<template>
  <b-card title="Listar Perfis">
    <listagemPerfis />
  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BCard,
} from 'bootstrap-vue'
import listagemPerfis from '../../Listagens/Perfis/ListarPerfis.vue'

export default {
  components: {
    BCard,
    listagemPerfis,
  },
}

</script>
